var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card p-shadow-6"},[_c('h1',[_vm._v("CRM | Parceiros")]),_c('Toast',{style:({ width: _vm.$isMobile() ? '85vw' : '' })}),_c('div',{staticStyle:{"height":"65vh"}},[_c('DataTable',{staticClass:"p-datatable-sm",attrs:{"value":_vm.clientTypes,"filters":_vm.filters,"filterDisplay":"row","loading":_vm.loading,"sortOrder":1,"sortField":"name","responsiveLayout":"scroll","removableSort":"","sortMode":"single","scrollable":true,"scrollHeight":"flex","autoLayout":true},on:{"update:filters":function($event){_vm.filters=$event}},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_vm._v(" A carregar. Por favor Aguarde... ")]},proxy:true},{key:"header",fn:function(){return [_c('div',{staticClass:"table-header"},[(_vm.can('createCrmClientTypes'))?_c('Button',{attrs:{"icon":"pi pi-plus"},on:{"click":_vm.create}}):_vm._e()],1)]},proxy:true}])},[_c('Column',{attrs:{"field":"name","filterField":"name","header":"Nome do Parceiro","sortable":"","filterMatchMode":"contains"},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [(_vm.can('createCrmClientTypes'))?_c('span',{staticClass:"clickable-item",on:{"click":function($event){return _vm.edit(slotProps.data)}}},[_vm._v(_vm._s(slotProps.data.name))]):_c('span',[_vm._v(_vm._s(slotProps.data.name))])]}},{key:"filter",fn:function({ filterModel, filterCallback }){return [_c('InputText',{staticClass:"p-column-filter",attrs:{"type":"text"},on:{"input":function($event){return filterCallback()}},model:{value:(filterModel.value),callback:function ($$v) {_vm.$set(filterModel, "value", $$v)},expression:"filterModel.value"}})]}}])}),_c('Column',{attrs:{"field":"color","filterField":"color","header":"Cor","sortable":"","filterMatchMode":"contains"},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [_c('span',{style:({
              'background-color': slotProps.data.color,
              height: '90%',
              width: '50px',
            })})]}}])}),_c('Column',{attrs:{"field":"active","header":"Activo","sortable":"","filterField":"active","dataType":"boolean"},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [_vm._v(" "+_vm._s(slotProps.data.active == 1 ? "Sim" : "Não")+" ")]}},{key:"filter",fn:function({ filterModel, filterCallback }){return [_c('Dropdown',{staticClass:"p-column-filter",attrs:{"options":[
              { value: 0, label: 'Não' },
              { value: 1, label: 'Sim' },
            ],"optionValue":"value","optionLabel":"label","showClear":true},on:{"input":function($event){return filterCallback()}},model:{value:(filterModel.value),callback:function ($$v) {_vm.$set(filterModel, "value", $$v)},expression:"filterModel.value"}})]}}])})],1)],1),_c('Dialog',{style:({ width: _vm.$isMobile() ? '100vw' : '40vw' }),attrs:{"visible":_vm.clientTypeModel.show,"header":(_vm.clientTypeModel.id == undefined ? 'Criar' : 'Actualizar') + ' Parceiro',"modal":true,"contentStyle":{ overflow: 'visible' }},on:{"update:visible":function($event){return _vm.$set(_vm.clientTypeModel, "show", $event)}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('Button',{staticClass:"p-button-success",attrs:{"label":'Guardar',"icon":"pi pi-check"},on:{"click":_vm.save}}),_c('Button',{staticClass:"p-button-danger",attrs:{"label":"Cancelar","icon":"pi pi-times"},on:{"click":_vm.closeForm}})]},proxy:true}])},[_c('form',{staticClass:"p-col-12",attrs:{"name":"crmClientTypeForm"},on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('div',{staticClass:"p-fluid p-formgrid p-grid"},[_c('div',{staticClass:"p-field p-col-12 p-md-8"},[_c('span',{staticClass:"p-float-label"},[_c('InputText',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:[
                { 'p-invalid': _vm.errors.has('clientName') },
                'form-control',
              ],attrs:{"type":"text","name":'clientName',"disabled":_vm.clientTypeModel.id==1},model:{value:(_vm.clientTypeModel.name),callback:function ($$v) {_vm.$set(_vm.clientTypeModel, "name", $$v)},expression:"clientTypeModel.name"}}),_c('label',{attrs:{"for":"clientName"}},[_vm._v("Nome")])],1),(_vm.errors.has('clientName'))?_c('small',{staticClass:"p-error",attrs:{"role":"alert"}},[_vm._v(" Nome é obrigatório ")]):_vm._e()]),_c('div',{staticClass:"p-field p-col-12 p-md-2 p-mt-2"},[_c('span',{staticClass:"p-float-label",style:({ top: '-0.75rem', left: '-0.5rem', 'font-size': '12px' })},[_c('label',{attrs:{"for":"color"}},[_vm._v("Cor")])]),_c('ColorPicker',{staticStyle:{"'top":"3px"},attrs:{"name":"color"},model:{value:(_vm.clientTypeModel.color),callback:function ($$v) {_vm.$set(_vm.clientTypeModel, "color", $$v)},expression:"clientTypeModel.color"}})],1),(_vm.clientTypeModel.id)?_c('div',{staticClass:"p-field p-col-12 p-md-2 p-mt-2"},[_c('span',{staticClass:"p-float-label",style:({ top: '-0.75rem', left: '-0.5rem', 'font-size': '12px' })},[_c('label',{attrs:{"for":"admission"}},[_vm._v("Ativa")])]),_c('InputSwitch',{staticStyle:{"'top":"3px"},attrs:{"name":"admission","disabled":_vm.clientTypeModel.id==1},model:{value:(_vm.clientTypeModel.active),callback:function ($$v) {_vm.$set(_vm.clientTypeModel, "active", $$v)},expression:"clientTypeModel.active"}})],1):_vm._e()])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }