<template>
  <div class="card p-shadow-6">
    <h1>CRM | Parceiros</h1>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <div style="height: 65vh">
      <DataTable
        :value="clientTypes"
        :filters.sync="filters"
        filterDisplay="row"
        :loading="loading"
        class="p-datatable-sm"
        :sortOrder="1"
        sortField="name"
        responsiveLayout="scroll"
        removableSort
        sortMode="single"
        :scrollable="true"
        scrollHeight="flex"
        :autoLayout="true"
      >
        <template #loading> A carregar. Por favor Aguarde... </template>
        <template #header>
          <div class="table-header">
            <Button
              icon="pi pi-plus"
              @click="create"
              v-if="can('createCrmClientTypes')"
            />
          </div>
        </template>
        <Column
          field="name"
          filterField="name"
          header="Nome do Parceiro"
          sortable
          filterMatchMode="contains"
        >
          <template #body="slotProps">
            <span
              v-if="can('createCrmClientTypes')"
              class="clickable-item"
              @click="edit(slotProps.data)"
              >{{ slotProps.data.name }}</span
            >
            <span v-else>{{ slotProps.data.name }}</span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          field="color"
          filterField="color"
          header="Cor"
          sortable
          filterMatchMode="contains"
        >
          <template #body="slotProps">
            <span
              :style="{
                'background-color': slotProps.data.color,
                height: '90%',
                width: '50px',
              }"
            ></span>
          </template>
        </Column>
        <Column
          field="active"
          header="Activo"
          sortable
          filterField="active"
          dataType="boolean"
        >
          <template #body="slotProps">
            {{ slotProps.data.active == 1 ? "Sim" : "Não" }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <Dropdown
              v-model="filterModel.value"
              @input="filterCallback()"
              :options="[
                { value: 0, label: 'Não' },
                { value: 1, label: 'Sim' },
              ]"
              optionValue="value"
              optionLabel="label"
              class="p-column-filter"
              :showClear="true"
            >
            </Dropdown>
          </template>
        </Column>
      </DataTable>
    </div>
    <Dialog
      :visible.sync="clientTypeModel.show"
      :style="{ width: $isMobile() ? '100vw' : '40vw' }"
      :header="
        (clientTypeModel.id == undefined ? 'Criar' : 'Actualizar') + ' Parceiro'
      "
      :modal="true"
      :contentStyle="{ overflow: 'visible' }"
    >
      <form name="crmClientTypeForm" class="p-col-12" @submit.prevent="save">
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12 p-md-8">
            <span class="p-float-label">
              <InputText
                type="text"
                v-validate="'required'"
                v-model="clientTypeModel.name"
                v-bind:class="[
                  { 'p-invalid': errors.has('clientName') },
                  'form-control',
                ]"
                :name="'clientName'"
                :disabled="clientTypeModel.id==1"
              />
              <label for="clientName">Nome</label>
            </span>
            <small v-if="errors.has('clientName')" class="p-error" role="alert">
              Nome é obrigatório
            </small>
          </div>
          <div class="p-field p-col-12 p-md-2 p-mt-2">
            <span
              class="p-float-label"
              :style="{ top: '-0.75rem', left: '-0.5rem', 'font-size': '12px' }"
            >
              <label for="color">Cor</label>
            </span>
            <ColorPicker
              v-model="clientTypeModel.color"
              name="color"
              style="'top: 3px;"
            />
          </div>

          <div class="p-field p-col-12 p-md-2 p-mt-2" v-if="clientTypeModel.id">
            <span
              class="p-float-label"
              :style="{ top: '-0.75rem', left: '-0.5rem', 'font-size': '12px' }"
            >
              <label for="admission">Ativa</label>
            </span>
            <InputSwitch
              v-model="clientTypeModel.active"
              name="admission"
              style="'top: 3px;"
              :disabled="clientTypeModel.id==1"
            />
          </div>
        </div>
      </form>
      <template #footer>
        <Button
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="save"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="closeForm"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import { FilterMatchMode } from "primevue/api/";
import crmService from "../services/crmClientType.service";
import ColorPicker from "primevue/colorpicker";
import { getRandomColor } from "../helpers/helpers";
export default {
  name: "crm-admin-client-type",
  components: {
    ColorPicker,
  },
  data() {
    return {
      clientTypes: [],
      loading: false,
      filters: {
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        color: { value: null, matchMode: FilterMatchMode.CONTAINS },
        active: { value: null, matchMode: FilterMatchMode.EQUALS },
      },
      clientTypeModel: {
        show: false,
        name: null,
        color: null,
        active: null,
      },
    };
  },
  async created() {
    this.loading = true;
    this.clientTypes = await crmService.getClients();
    this.loading = false;
  },
  methods: {
    closeForm() {
      this.$validator.pause();
      this.$validator.reset();
      return (this.clientTypeModel = { show: false });
    },
    async create() {
      return (this.clientTypeModel = {
        show: true,
        color: await getRandomColor(),
      });
    },
    edit(data) {
      this.clientTypeModel = {
        show: true,
        id: data.id,
        color: data.color,
        name: data.name,
        active: data.active == 1 ? true : false,
      };
    },
    save() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          return;
        }
        this.$validator.pause();
        this.$validator.reset();

        if (this.clientTypeModel.id === undefined) {
          return this.saveNew();
        }

        return this.update();
      });
    },
    saveNew() {
      let params = {
        name: this.clientTypeModel.name,
        color: this.clientTypeModel.color.startsWith("#")
          ? this.clientTypeModel.color
          : `#${this.clientTypeModel.color}`,
      };
      this.loading = true;
      return crmService.createClient(params).then((response) => {
        this.loading = false;
        if (!response) {
          return this.$toast.add({
            severity: "error",
            summary: "Erro",
            detail: "Ocorreu um erro ao inserir parceiro",
            life: 3000,
          });
        }
        this.clientTypes.push(response);
        this.$toast.add({
          severity: "success",
          summary: "Parceiro atualizado",
          detail: "O parceiro foi atualizado com sucesso",
          life: 3000,
        });
        return this.closeForm();
      });
    },
    update() {
      let params = {
        name: this.clientTypeModel.name,
        color: this.clientTypeModel.color.startsWith("#")
          ? this.clientTypeModel.color
          : `#${this.clientTypeModel.color}`,
        active: this.clientTypeModel.active ? 1 : 0,
      };

      this.loading = true;
      return crmService
        .updateClient(this.clientTypeModel.id, params)
        .then((response) => {
          this.loading = false;
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: "Erro",
              detail: "Ocorreu um erro ao actualizar parceiro",
              life: 3000,
            });
          }
          let index = this.clientTypes.findIndex((c) => c.id == this.clientTypeModel.id)
          this.clientTypes[index].name = response.name;
          this.clientTypes[index].color = response.color;
          this.clientTypes[index].active = response.active;
          this.$toast.add({
            severity: "success",
            summary: "Parceiro criado",
            detail: "O parceiro foi atualizado com sucesso",
            life: 3000,
          });
          return this.closeForm();
        });
    },
  },
};
</script>
